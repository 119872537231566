import { useDirection } from '@endeavorpal/hooks'
import React from 'react'
import EndeavorFancySVG, { Props } from './EndeavorFancySVG'

const ChatIcon: React.FC<Props> = (props: Props) => {
  const { isRTL } = useDirection()
  const svgProps = {
    width: props.width || '32',
    height: props.height || '32',
    viewBox: props.viewBox || '0 0 32 32',
    fill: props.fill || '#FFFFFF',
    transform: props.transform || isRTL ? 'scale(-1, 1)' : 'scale(1, 1)',
  }
  return (
    <EndeavorFancySVG
      width={svgProps.width}
      height={svgProps.height}
      viewBox={svgProps.viewBox}
      fill={svgProps.fill}
      transform={svgProps.transform}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.333 20.3693C22.3557 20.38 22.377 20.3947 22.397 20.4107L26.0263 23.4347C26.3237 23.6827 26.7397 23.7373 27.0903 23.572C27.4423 23.408 27.6663 23.0547 27.6663 22.6667V20.1947C28.2623 20.0253 28.813 19.7053 29.2597 19.26C29.9463 18.572 30.333 17.6387 30.333 16.6667V6.66667C30.333 5.69467 29.9463 4.76133 29.2597 4.07333C28.5717 3.38667 27.6383 3 26.6663 3H14.6663C13.6943 3 12.761 3.38667 12.073 4.07333C11.3863 4.76133 10.9997 5.69467 10.9997 6.66667V7H17.333C18.6597 7 19.9303 7.52667 20.869 8.464C21.8063 9.40267 22.333 10.6733 22.333 12V20.3693Z'
        fill={svgProps.fill}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21 11.9997C21 11.0277 20.6133 10.0943 19.9267 9.40634C19.2387 8.71967 18.3053 8.33301 17.3333 8.33301H5.33333C4.36133 8.33301 3.428 8.71967 2.74 9.40634C2.05333 10.0943 1.66667 11.0277 1.66667 11.9997V21.9997C1.66667 22.9717 2.05333 23.905 2.74 24.593C3.18667 25.0383 3.73733 25.3583 4.33333 25.5277V27.9997C4.33333 28.3877 4.55733 28.741 4.90933 28.905C5.26 29.0703 5.676 29.0157 5.97333 28.7677L9.60267 25.7437C9.66267 25.6943 9.73867 25.6663 9.816 25.6663H17.3333C18.3053 25.6663 19.2387 25.2797 19.9267 24.593C20.6133 23.905 21 22.9717 21 21.9997V11.9997ZM14.6667 15.6663H10.6667C10.1147 15.6663 9.66667 15.2183 9.66667 14.6663C9.66667 14.1143 10.1147 13.6663 10.6667 13.6663H14.6667C15.2187 13.6663 15.6667 14.1143 15.6667 14.6663C15.6667 15.2183 15.2187 15.6663 14.6667 15.6663ZM14.6667 19.6663H8C7.448 19.6663 7 19.2183 7 18.6663C7 18.1143 7.448 17.6663 8 17.6663H14.6667C15.2187 17.6663 15.6667 18.1143 15.6667 18.6663C15.6667 19.2183 15.2187 19.6663 14.6667 19.6663Z'
        fill={svgProps.fill}
      />
    </EndeavorFancySVG>
  )
}

export default ChatIcon
