import React from 'react'
import EndeavorFancySVG, { Props } from './EndeavorFancySVG'

const AddIcon: React.FC<Props> = (props: Props) => {
  return (
    <EndeavorFancySVG
      {...props}
      width={props.width || '18'}
      height={props.height || '18'}
      viewBox={props.viewBox || '0 0 18 18'}
      fill={props.fill || '#D0D0D0'}
    >
      <g id='add 1' clipPath='url(#clip0_537_877)'>
        <g id='layer1'>
          <path
            id='path823'
            fillRule='evenodd'
            clipRule='evenodd'
            d='M9.01691 0.75C4.46228 0.75 0.748053 4.45838 0.748047 9.01299C0.748036 13.5676 4.46227 17.2804 9.01691 17.2804C13.5715 17.2804 17.2784 13.5676 17.2784 9.01299C17.2784 4.45838 13.5715 0.75 9.01691 0.75ZM9.01691 2.25236C12.7596 2.25236 15.7761 5.27031 15.7761 9.01299C15.7761 12.7557 12.7596 15.778 9.01691 15.778C5.27421 15.778 2.2504 12.7557 2.25041 9.01299C2.25041 5.27031 5.27422 2.25236 9.01691 2.25236ZM9.00517 5.24682C8.9059 5.24778 8.8078 5.2684 8.71655 5.3075C8.6253 5.34661 8.5427 5.4034 8.47353 5.47462C8.40437 5.54583 8.35 5.63005 8.31357 5.7224C8.27715 5.81476 8.25939 5.91341 8.26133 6.01267V8.26328H6.01072C5.98481 8.26194 5.95886 8.26194 5.93296 8.26328C5.73354 8.27359 5.54638 8.3627 5.41266 8.511C5.27894 8.6593 5.20961 8.85465 5.21992 9.05407C5.23023 9.25349 5.31934 9.44065 5.46764 9.57437C5.61595 9.70809 5.8113 9.77742 6.01072 9.76711H8.26133V12.0177C8.26133 12.2171 8.34054 12.4084 8.48156 12.5494C8.62257 12.6904 8.81382 12.7696 9.01324 12.7696C9.21266 12.7696 9.40391 12.6904 9.54492 12.5494C9.68594 12.4084 9.76515 12.2171 9.76515 12.0177V9.76711H12.0158C12.2152 9.76711 12.4064 9.68789 12.5474 9.54688C12.6885 9.40587 12.7677 9.21461 12.7677 9.01519C12.7677 8.81577 12.6885 8.62452 12.5474 8.48351C12.4064 8.3425 12.2152 8.26328 12.0158 8.26328H9.76515V6.01267C9.76712 5.91205 9.74884 5.81207 9.71142 5.71865C9.674 5.62523 9.61819 5.54028 9.5473 5.46885C9.47641 5.39742 9.3919 5.34095 9.29877 5.30281C9.20564 5.26467 9.1058 5.24563 9.00517 5.24682Z'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_537_877'>
          <rect width='18' height='18' />
        </clipPath>
      </defs>
    </EndeavorFancySVG>
  )
}

export default AddIcon
