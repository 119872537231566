import React from 'react'
import EndeavorFancySVG, { Props } from './EndeavorFancySVG'

const LogoTextIcon: React.FC<Props> = (props: Props) => {
  return (
    <EndeavorFancySVG
      {...props}
      fill={'none'}
      width={props.width || '167'}
      height={props.height || '37'}
      viewBox={props.viewBox || '0 0  167 37'}
    >
      <path
        d='M16.574 0.00690969C21.91 0.00690969 26.2726 4.31436 26.2726 9.65724V36.5442H18.5758V22.9937L7.69679 25.9689V36.5373H0V9.65033C0 4.31435 4.30743 0 9.69864 0H16.574V0.00690969ZM9.69864 7.64848C8.62178 7.64848 7.69679 8.57347 7.69679 9.65033V18.1202L18.5758 15.1934V9.65033C18.5758 8.57347 17.6509 7.64848 16.574 7.64848H9.69864Z'
        fill={props.fill || 'currentColor'}
      />
      <path
        d='M48.391 0.00878906C53.5751 0.00878906 57.9861 4.37146 57.9861 9.65912V36.5461H50.3377V9.65912C50.3377 8.58226 49.5162 7.60895 48.3841 7.60895H39.2998V36.553H31.6514V0.00878906H48.391Z'
        fill={props.fill || 'currentColor'}
      />
      <path
        d='M81.2813 0.00878906C86.4102 0.00878906 90.3656 4.57855 90.3656 9.65912V11.1985H82.7171V9.65912C82.7171 8.58226 81.7921 7.65725 80.7152 7.65725H73.2255C72.0934 7.65725 71.1201 8.58226 71.1201 9.65912V15.147C71.1201 15.5542 71.2237 15.8166 71.3272 15.8649C71.3755 15.9132 71.4791 15.9132 71.6862 15.9132C71.7897 15.9132 71.9968 15.9132 72.1487 15.8649L78.8722 14.2772C78.8722 14.2772 80.0526 14.0218 80.6669 14.0218C85.7958 14.0218 90.3656 18.2809 90.3656 23.6169V26.9027C90.3656 32.2939 85.9546 36.6013 80.6669 36.6013H73.1772C67.786 36.6013 63.4786 32.2939 63.4786 26.9027V26.5921H71.1271V26.9027C71.1271 28.0348 72.1004 29.0081 73.1772 29.0081H80.6669C81.7438 29.0081 82.7171 28.0348 82.7171 26.9027V23.6169C82.7171 22.54 81.9509 21.8221 80.9706 21.5667C80.66 21.5184 73.8882 23.3132 73.8882 23.3132C73.1703 23.5203 72.4524 23.5686 71.7345 23.5686C69.8362 23.5686 68.0897 22.9542 66.6539 21.8221C64.7073 20.2344 63.4717 17.8736 63.4717 15.1539V9.71434C63.4717 4.37836 67.8343 0.0156988 73.2186 0.0156988H81.2813V0.00878906Z'
        fill={props.fill || 'currentColor'}
      />
      <path
        d='M112.374 0.00690969C117.71 0.00690969 122.072 4.31436 122.072 9.65724V36.5442H114.376V22.9937L103.497 25.9689V36.5373H95.7998V9.65033C95.7998 4.31435 100.114 0 105.498 0H112.374V0.00690969ZM105.498 7.64848C104.422 7.64848 103.497 8.57347 103.497 9.65033V18.1202L114.376 15.1934V9.65033C114.376 8.57347 113.451 7.64848 112.374 7.64848H105.498Z'
        fill={props.fill || 'currentColor'}
      />
      <path
        d='M144.446 0.00878906C149.733 0.00878906 154.096 4.31624 154.096 9.65912V13.152C154.096 16.9003 151.942 20.1792 148.608 21.7738L152.612 36.553H144.708L141.319 23.824L134.906 25.5152V36.5461H127.312V0.00878906H144.446ZM134.906 17.6113L145.426 14.8916C145.992 14.5326 146.454 13.863 146.454 13.1451V9.65221C146.454 8.57535 145.585 7.65036 144.453 7.65036H134.906V17.6113Z'
        fill={props.fill || 'currentColor'}
      />
      <path d='M158.865 36.5461V0.00878906H166.514V36.5461H158.865Z' fill={props.fill || 'currentColor'} />
    </EndeavorFancySVG>
  )
}

export default LogoTextIcon
