import { useDirection } from '@endeavorpal/hooks'
import React from 'react'
import EndeavorFancySVG, { Props } from './EndeavorFancySVG'

const PrayingIcon: React.FC<Props> = (props: Props) => {
  const { isRTL } = useDirection()
  const svgProps = {
    width: props.width || '32',
    height: props.height || '32',
    viewBox: props.viewBox || '0 0 32 32',
    fill: props.fill || '#FFFFFF',
    transform: props.transform || isRTL ? 'scale(-1, 1)' : 'scale(1, 1)',
  }
  return (
    <EndeavorFancySVG
      width={svgProps.width}
      height={svgProps.height}
      viewBox={svgProps.viewBox}
      fill={svgProps.fill}
      transform={svgProps.transform}
    >
      <path d='M25.2132 31.3333H21.9999V28H22.8332C25.5132 28 26.7066 31.3333 25.2132 31.3333Z' fill={svgProps.fill} />
      <path
        d='M8.56689 25.0138L13.1736 23.2404C13.5839 23.0829 13.949 22.8265 14.2362 22.4938C14.5235 22.1612 14.7241 21.7627 14.8202 21.3338C17.4869 21.6604 19.9669 20.9804 20.3202 18.5004L20.6669 16.0938C20.6791 16.0062 20.674 15.9171 20.6519 15.8315C20.6297 15.746 20.5909 15.6656 20.5376 15.595C20.4301 15.4525 20.2704 15.3585 20.0936 15.3338C19.9167 15.309 19.7373 15.3555 19.5948 15.463C19.4523 15.5706 19.3583 15.7303 19.3336 15.9071L18.9936 18.2871C18.5269 21.5471 11.5269 19.4604 9.88689 18.3738L11.3336 15.4338L15.3336 16.0004C15.5669 16.0004 15.4602 16.3138 15.7536 13.9604C15.8376 13.2801 16.0952 12.6327 16.5014 12.0805C16.9077 11.5284 17.449 11.0899 18.0736 10.8071L19.3336 10.2271C19.5843 10.1068 19.8573 10.0401 20.1353 10.0313C20.4132 10.0225 20.6899 10.0717 20.9478 10.1758C21.2056 10.28 21.4389 10.4367 21.6327 10.6361C21.8266 10.8355 21.9767 11.0731 22.0736 11.3338C22.8136 13.3338 22.6669 13.3938 22.6669 24.6671C22.6669 25.6671 22.1602 26.6671 21.1536 26.6671H14.0002C13.8234 26.6671 13.6538 26.7373 13.5288 26.8624C13.4038 26.9874 13.3336 27.157 13.3336 27.3338C13.3336 27.5106 13.4038 27.6801 13.5288 27.8052C13.6538 27.9302 13.8234 28.0004 14.0002 28.0004H20.6669V31.3338H8.00023C7.46979 31.3338 6.96108 31.1231 6.58601 30.748C6.21094 30.3729 6.00023 29.8642 6.00023 29.3338V28.7471C6.00043 27.9383 6.24584 27.1485 6.70406 26.482C7.16228 25.8155 7.81178 25.3036 8.56689 25.0138Z'
        fill={svgProps.fill}
      />
      <path
        d='M20.1336 4.88014C13.7336 2.02681 14.3203 2.34681 14.4069 2.21348C14.7649 1.624 15.3055 1.1675 15.9466 0.913332C16.5877 0.659167 17.2942 0.62124 17.9589 0.805313C18.6235 0.989386 19.2099 1.3854 19.6289 1.93316C20.0479 2.48093 20.2766 3.15051 20.2803 3.84014C20.2689 4.19107 20.2197 4.53975 20.1336 4.88014Z'
        fill={svgProps.fill}
      />
      <path
        d='M19.3333 7.44654L19.68 8.74654C18.8088 8.97617 17.9692 9.31202 17.18 9.74654C16.8933 8.66654 16.8733 8.76654 16.94 8.7332C16.6086 8.82887 16.2696 8.89579 15.9267 8.9332C15.5995 8.95868 15.2744 8.86259 15.0136 8.66332C14.7528 8.46405 14.5747 8.17561 14.5133 7.8532C14.1733 5.99987 13.3333 5.87987 13.8333 3.5332L20 6.27987C20 6.9132 20 6.90654 19.3333 7.44654Z'
        fill={svgProps.fill}
      />
      <path
        d='M7.99991 14L10.0732 15.0333L8.79324 17.5933C8.50083 17.4134 8.24758 17.1766 8.04858 16.8968C7.84958 16.617 7.70889 16.3001 7.63487 15.9648C7.56086 15.6296 7.55503 15.2829 7.61774 14.9453C7.68045 14.6078 7.81042 14.2863 7.99991 14Z'
        fill={svgProps.fill}
      />
    </EndeavorFancySVG>
  )
}

export default PrayingIcon
